import React from 'react';
import GlobalLayout from "../layouts/globalLayout";
import * as styles from "./shippingreturns.module.css";

export default function ShippingReturns() {
    return (
        <GlobalLayout>
            <p className={styles.text}>
                <p><strong>Shipping</strong></p>
                <p>
                    <span>
                        Orders placed before 12pm PST will be shipped out on the same day. Weekend and Public Holiday orders will be shipped the next business day.
                    </span>
                    <br />
                    <br />
                    <em>However, as we are a small team, please allow 24-48 hours processing time before reaching out.
                    </em>
                </p>
                <p>Orders will ship with USPS and will arrive in 5-8 business days. We do not provide expedited shipping options at this time.&nbsp;</p>
                <p>If your subtotal is over $150 you will receive free shipping. All other orders is a flat rate of $8 for shipping.&nbsp;</p>
                <p>Items&nbsp;ship from San Diego, CA.&nbsp;</p>
                <p>International shipping outside the United States is not available at this time.</p>

                <div><strong>Refund Policy</strong></div>
                <div>Did the magic not happen? We try our best to bring the magic to you, but understand that sometimes returns happen. For all eligible returns Love Shack will provide you with store credit for the amount of the product. Please note that the original shipping&nbsp;cost will not be refunded in any way.</div>
                <div>&nbsp;</div>
                <p><em>Returns must be requested within 7 days of arrival, and returned to us within 14 days.</em></p>
                <p><em>Once the product is returned you will be issued store credit for the value of the product. This credit will be valid for one year from the issue date.&nbsp;</em></p>
                <div><strong>Terms &amp; Conditions</strong></div>
                <div>Item/s must be in original condition with proper tags still attached. Item/s can not be altered in any way. Love Shack has the right to refuse any returns that do not meet these requirements.&nbsp;</div>
                <div></div>
                <div></div>
                <p><strong>Damages &amp; Issues</strong> <br />Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item/s. Email us at&nbsp;<a data-mce-fragment="1" href="mailto:info@loveshack-vintage.com" data-mce-href="mailto:info@loveshack-vintage.com">info@loveshack-vintage.com</a>&nbsp;with accompanying images so that we may be able to asses the items and provide you with further assistance. Please note that our items are vintage and may have some normal wear.&nbsp;</p>
                <div><strong>Order Cancelation</strong></div>
                <div>Once an order is placed we are unable to cancel or make any changes.&nbsp;</div>
                <div></div>
                <div></div>
                <div><strong>Return Cost</strong></div>
                <div><span data-mce-fragment="1">The return shipping cost&nbsp;is to be covered by the customer, and this will be determined by your local post office. We recommend sending the return with tracking to avoid any issues.&nbsp;Unfortunately, if the package does not reach us safely we will not be able to process your return. Please be sure to re-pack items with care and use adequate packing materials to ensure safe delivery.&nbsp;</span></div>
                <div><span data-mce-fragment="1"></span></div>
                <div><span data-mce-fragment="1"></span></div>
                <div><strong>Starting a Return</strong></div>
                <p>If you are not happy with your item please contact us at&nbsp;<a data-mce-fragment="1" href="mailto:info@loveshack-vintage.com" data-mce-href="mailto:info@loveshack-vintage.com">info@loveshack-vintage.com</a>&nbsp;within 7 days of receiving your order.&nbsp;</p>
                <div><strong>Processing Time</strong></div>
                <div>Once the return items have been received please allow 2-3 business days for your store credit to be issued.&nbsp;&nbsp;</div>
            </p>
        </GlobalLayout>
    )
}